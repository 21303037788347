import styled from "@emotion/styled"

import StyledFlex, { StyledFlexChild } from "../flex/flex-styles"

const FlexContainer = styled(StyledFlex)`
  height: calc(100vh - 160px);
  align-items: center;

  .mobile-hidden {
    display: none;
  }

  @media (min-width: 759px) {
    .mobile-hidden {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    height: 100vh;
    padding: 2em;
  }

  .mobile-hidden {
    background: var(--lightville-blue-lighter);
    height: 70vh;
    max-height: 750px;
  }
`
export const FlexContainerChild = styled(StyledFlexChild)`
  display: flex;
  justify-content: center;
  align-items: center;

  .text-container {
    text-align: center;
  }

  @media (min-width: 759px) {
    .text-container {
      padding: 2em;
    }
  }
`

export default FlexContainer
