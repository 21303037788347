import React from "react"

import { SEO, Layout, Container, LVLink } from "../components"
import FlexContainer, { FlexContainerChild } from "../components/styles/404"
import PageNotFoundImg from "../images/icons/404.svg"

const NotFoundPage = ({ location }) => (
  <Layout location={location} hideTemplateParts={true}>
    <SEO
      title="Page Not Found"
      description="This page no longer exists on this site"
    />

    <Container>
      <FlexContainer>
        <FlexContainerChild erChild className="mobile-hidden">
          <img src={PageNotFoundImg} alt="Page Not Found" />
        </FlexContainerChild>
        <FlexContainerChild>
          <div className="text-container">
            <span className="text-caption">404</span>
            <h1>Page Not Found</h1>
            <p>
              The page you’re looking for does not exist or has been removed.
              You can go{" "}
              <LVLink variant="vanilla" to="/contact-us">
                send us a message
              </LVLink>{" "}
              or go to the{" "}
              <LVLink variant="vanilla" to="/">
                Home page
              </LVLink>
              . We’re sorry this happened.
            </p>
            <LVLink to="/">Go Back Home</LVLink>
          </div>
        </FlexContainerChild>
      </FlexContainer>
    </Container>
  </Layout>
)

export default NotFoundPage
